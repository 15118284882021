<template>
  <body>
    <div class="banner-bg">
      <div class="banner-content">
        <v-container>
          <v-divider></v-divider>
            <v-col cols="12" sm="12" md="6">
              <v-row class="banner-h" align="end">
                <v-col cols="1" class="line-banner pa-1 d-none d-sm-flex">
                </v-col>
                <v-col cols="12" sm="11" class="pa-0">
                  <span class="white-text heading mgt-12px">
                    PERSUASIVE PRESENTATION &amp; NEGOTIATION PATHWAY<br/>
                    <span class="caption">
                      เสริมสร้างทักษะของการสื่อสาร เพื่อเพิ่มประสิทธิภาพของการเจรจา
                    </span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
        </v-container>
      </div>
    </div>
    <v-app class="ct-mw">
      <v-container>
        <v-row>
          <!-- Mobile -->
          <v-col cols="12">
            <v-row justify="center">
              <router-link
                :to="`/payment/cart/${sku}`"
                class="button hidden-md-and-up ltsp">
                <v-btn color="error"><strong> BUY NOW </strong></v-btn>
              </router-link>
              <!-- <v-btn
                v-else
                class="button hidden-md-and-up ltsp"
                disabled
              >
                <strong> SOLD OUT </strong>
              </v-btn> -->
            </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="4" class="hidden-md-and-up">
            <v-container class="white-bg shadow">
            <br/>
              <v-row dense no-gutters
                v-for="salebox in salebox"
                :key="salebox.id">
                <v-col cols="2">
                  <v-row justify="center">
                    <v-img
                      v-if="salebox.imgicon"
                      :src="`${salebox.imgicon}`"
                      max-width="30px">
                    </v-img>
                    <v-img
                      v-else-if="!salebox.imgicon && salebox.icon"
                      max-width="30px"
                    >
                      <v-icon right>
                      {{salebox.icon}}
                      </v-icon>
                    </v-img>
                  </v-row>
                </v-col>
                <v-col v-if="'link' in salebox" class="text-content">
                  <a :href="`${salebox.link}`">
                    {{ salebox.title }}</a>
                </v-col>
                <v-col v-else-if="salebox.blank" cols="11"><br/></v-col>
                <v-col v-else class="text-content">
                  {{ salebox.title }}
                </v-col>
              </v-row>
            </v-container>

            <v-responsive :aspect-ratio="16/9" class="pt-6">
              <iframe
                :src="teaserLink"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
              >
              </iframe>
            </v-responsive>

          </v-col>
          <!-- About Product  -->
          <v-col cols="12" md="8">
            <v-content class="white-bg mt-24px pt-0">
              <div class="pd-24px text-content">
                ชุดวิชาสำหรับผู้ที่ต้องการนำเสนอเเละเจรจาต่อรองได้อย่างมีประสิทธิภาพ
                ประกอบไปด้วยหลักในการจัดเตรียมโครงสร้าง องค์ประกอบ ขั้นตอน
                เทคนิคการอุปมาและการเปรียบเทียบ การเลือกใช้ภาษาและอวัจนภาษาต่าง ๆ
                รวมถึงการออกแบบและเลือกใช้สื่อในการนำเสนออย่างเหมาะสมจึงจะสามารถทำให้การนำเสนอนั้น
                ๆ เกิดประสิทธิผลสูงสุด
                <br/><br/>

                <v-img
                  :src="require('../../assets/image/presentationinfo.jpg')"
                ></v-img>
                <br/>

                <span class="title font-weight-black">รายวิชาที่เรียน</span><br/>

                <strong>COURSE 1: Intro to Social Psychology
                  for Business Communication</strong><br/>
                เรียนรู้เกียวกับการสื่อสารของมนุษย์ในเชิงธุรกิจ ลักษณะขององค์กรธุรกิจ
                โดยเฉพาะหลักในการโน้มน้าวใจ และการมีอิทธิพลต่อพฤติกรรมของผู้รับสาร<br/>

                <strong>COURSE 2: Storytelling Structure and Media Design</strong><br/>
                เรียนรู้เกียวกับการเล่าเรื่อง การจัดทําโครงสร้างองค์ประกอบ ขั้นตอน
                เทคนิคการอุปมาและการเปรียบเทียบ ตลอดจนการออกแบบสื่อ<br/>

                <strong>COURSE 3: Effective Presentation Delivery</strong><br/>
                เเรียนรู้เกี่ยวกับหลักการนําเสนอ การจัดเตรียมโครงสร้าง องค์ประกอบ
                ขั้นตอน เทคนิคการอุปมาและการเปรียบเทียบ การเลือกใช้ภาษาและอวัจนภาษา
                รวมถึงการออกแบบและเลือกใช้สื่อที่เหมาะสม ในการนําเสนอต่าง ๆ<br/>

                <strong>COURSE 4: Negotiation and Value Creation</strong><br/>
                เรียนรู้เกี่ยวกับกลยุทธ์ที่ใช้ในการเจรจาต่อรอง ประเภทของการเจรจาต่อรอง
                หลักการจัดการความตึงเครียดระหว่างการเจรจาต่อรอง หลักการฟังเชิงรุก
                (Active listening) ข้อควรระวังในเจรจาต่อรอง เเละกระบวนการเจรจาต่อรอง<br/>
                <br/>

                <span class="title font-weight-black">เนื้อหาหลักสูตร</span><br/>
                <v-row>
                  <v-col cols="1" class="px-1 text-end">
                    <v-icon>query_builder</v-icon>
                  </v-col>
                  <v-col  cols="11" sm="8" class="px-0">
                    เวลาเรียนรู้ รวมทำโปรเจกต์ และแบบฝึกหัด ประมาณ 30 ชั่วโมง
                  </v-col>
                  <v-col cols="1" class="px-1 text-end">
                    <v-icon>play_circle_outline</v-icon>
                  </v-col>
                  <v-col cols="11" sm="2" class="px-0">
                     59 คลิป
                  </v-col>
                </v-row>
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(item, i) in courseOutline"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      <span class="font-weight-black navy-text">
                        <v-row class="pa-0">
                          <v-col cols="12" sm="3"
                          class="py-0 pr-sm-0 text-sm-center header-content">
                            COURSE {{item.course}}:
                          </v-col>
                          <v-col cols="12" sm="9" class="py-0 pl-sm-0 header-content">
                            {{item.name}}
                          </v-col>
                        </v-row>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel
                          v-for="(part, index) in item.parts"
                          :key="index"
                        >
                          <v-expansion-panel-header>
                            <span
                              class="font-weight-medium navy-text"
                            >
                              <v-row class="py-0 my-0">
                                <v-col cols="12" sm="2" class="pa-0 header-content">
                                  Part {{part.part}}:
                                </v-col>
                                <v-col cols="12" sm="7" class="pa-0 px-1 header-content">
                                   {{part.name}}
                                </v-col>
                                <v-col cols="12" sm="3"
                                  class="pa-0 text-sm-end pr-3 header-content"
                                >
                                  {{part.chapters.length}} คลิป
                                </v-col>
                              </v-row>
                            </span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-list>
                              <v-list-item
                                v-for="(chap, indexChap) in part.chapters"
                                :key="indexChap"
                              >
                                <v-row class="pa-0 base-text">
                                  <v-col class="pa-0 px-1 text-content" cols="1">
                                    -
                                  </v-col>
                                  <v-col cols="11" class="pa-0 px-1 text-content">
                                    {{chap}}
                                  </v-col>
                                </v-row>
                              </v-list-item>
                            </v-list>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br/>


                <span class="title font-weight-black">Persuasive Presentation
                  & Negotiation Pathway เหมาะกับใคร</span><br/>
                เหมาะสำหรับทุกอาชีพที่ต้องการเสริมสร้างทักษะทางด้านการสื่อสาร
                นำเสนอแบบโน้มน้าวใจ และเจรจาต่อรองเชิงธุรกิจ
                เช่น ผู้บริหาร, เจ้าของธุรกิจ/สตาร์ทอัพ, ผู้จัดการ, พนักงานทั่วไป และ
                บัณฑิตจบใหม่ เป็นต้น
                <br/><br/>

                <span class="title font-weight-black">สิ่งที่คุณจะได้รับ</span><br/>
                การที่จะให้การเจรจาต่อรองเกิดผลสำเร็จ
                จะต้องมีการเตรียมความพร้อมทางด้านกลยุทธ์ก่อนที่จะมีการเจรจาต่อรองจริงเกิดขึ้น
                <!-- eslint-disable-next-line max-len -->
                รวมถึงจะต้องเรียนรู้กระบวนการและเทคนิคที่ใช้เพื่อสร้างผลประโยชน์สูดสุดสําหรับทุกฝ่าย<br/>

                <br/>
                <v-img
                  class="d-none d-sm-flex"
                  :src="require('../../assets/image/learningperiod-pathway-desktop.jpg')"
                ></v-img>

                <v-img
                  class="d-flex d-sm-none"
                  :src="require('../../assets/image/learningperiod-pathway-mobile.png')"
                ></v-img>

                <br/><br/>

                <span class="title font-weight-black">อาจารย์ผู้สอน</span><br/>
                <v-row>
                  <v-col cols="12" sm="6"
                    v-for="(instructor, index) in instructors"
                    v-bind:key="index"
                  >
                    <v-card flat>
                      <v-container>
                        <v-row justify="center">
                          <v-img
                            aspect-ratio="1"
                            :src="instructor.img"
                            max-height="150px"
                            max-width="150px"
                          ></v-img>
                        </v-row>
                        <v-row justify="center" class="text-content font-weight-bold navy-text">
                          {{instructor.tth}} {{instructor.fth}} {{instructor.lth}}
                        </v-row>
                        <v-row justify="center" class="text-center prof font-weight-light">
                          {{instructor.ten}} {{instructor.fen}} {{instructor.len}}<br/>
                          {{instructor.dep}}<br/>
                          {{instructor.fac}}<br/>
                          จุฬาลงกรณ์มหาวิทยาลัย
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
                <br/>


                <span class="title font-weight-black">FAQ</span><br/><br/>
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(item,i) in faqs"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      <span class="header-content font-weight-bold navy-text">{{item.q}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="text-content">{{item.a}}</span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br/><br/>

                สอบถามข้อมูลเพิ่มเติม<br/>
                LINE Official Account: <a href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a><br/>
                Facebook: <a href="https://www.facebook.com/Achieve.plus.th" target="_blank">Achieve Plus</a><br/><br/>
              </div>

            </v-content>
          </v-col>
          <!-- Desktop -->
          <v-col cols="12" md="4" class="hidden-sm-and-down">
            <v-responsive :aspect-ratio="16/9" class="pt-6">
              <iframe
                :src="teaserLink"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
              >
              </iframe>
            </v-responsive>
            <v-content class="white-bg shadow mt-24px">
              <v-col cols="12">
                <v-row justify="center">
                  <router-link
                    :to="`/payment/cart/${sku}`"
                    class="button ltsp">
                    <v-btn color="error"><strong> BUY NOW </strong></v-btn>
                  </router-link>
                  <!-- <v-btn
                    v-else
                    class="button ltsp"
                    disabled
                  >
                    <strong> SOLD OUT </strong>
                  </v-btn> -->
                </v-row>
              </v-col>
              <v-container>
                <br/>
                <v-row dense no-gutters
                  v-for="salebox in salebox"
                  :key="salebox.id">
                  <v-col cols="2">
                    <v-row justify="center">
                      <v-img
                        v-if="salebox.imgicon"
                        :src="`${salebox.imgicon}`"
                        max-width="30px">
                      </v-img>
                      <v-img
                        v-else-if="!salebox.imgicon && salebox.icon"
                        max-width="30px"
                      >
                        <v-icon right>
                        {{salebox.icon}}
                        </v-icon>
                      </v-img>
                    </v-row>
                  </v-col>
                  <v-col v-if="'link' in salebox" class="text-content">
                    <a :href="`${salebox.link}`">
                      {{ salebox.title }}</a>
                  </v-col>
                  <v-col v-else-if="salebox.blank" cols="11"><br/></v-col>
                  <v-col v-else class="text-content">
                    {{ salebox.title }}
                  </v-col>
                </v-row>
              </v-container>

            </v-content>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'pathwayDetail',
  props: ['pathwayId'],
  data() {
    return {
      card_text: '',
      sku: 'pw0020008',
      teaserLink: 'https://www.youtube.com/embed/khRy4z4YeNo?rel=0&modestbranding=1',
      salebox: [
        {
          title: 'ชุดวิชา (pathway)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 1,
        },
        {
          title: 'เรียนได้ทันทีเมื่อชำระเงิน', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 2,
        },
        {
          title: '3 เดือน', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 3,
        },
        {
          title: 'เรียนออนไลน์', imgicon: require('@/assets/image/icon-14.svg'), id: 4,
        },
        {
          title: 'ระดับพื้นฐาน (Fundamental level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 5,
        },
        {
          blank: true,
        },
        {
          title: '30 ชั่วโมง วิดีโอสั้น เข้าใจง่าย', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-04.svg'), id: 6,
        },
        {
          title: '4 รายวิชา 14 บทเรียน 59 คลิป', id: 7,
        },
        {
          title: '5,500 บาท', icon: 'local_offer', id: 8,
        },
        // {
        //   title: 'Achieve+ Member รับส่วนลด 25%',
        // link: '/member', icon: 'fas fa-star',
        // imgicon: require('@/assets/image/icon-13.svg'), id: 9,
        // },
      ],
      courseOutline: [
        {
          course: 1,
          name: 'Intro to Social Psychology for Business Communication',
          des: 'เรียนรู้เกียวกับการสื่อสารของมนุษย์ในเชิงธุรกิจ ลักษณะขององค์กรธุรกิจ โดยเฉพาะหลักในการโน้มน้าวใจ และการมีอิทธิพลต่อพฤติกรรมของผู้รับสาร',
          parts: [
            {
              part: 1,
              name: 'การสื่อสารในเชิงธุรกิจ',
              chapters: [
                'การสื่อสารของมนุษย์',
                'ความน่าเชื่อถือ',
                'บุคลิกภาพของนักสื่อสาร',
                'ความสามารถด้านการสื่อสารเชิงธุรกิจ',
                'การลดความขัดแย้ง',
                'ลักษณะของการสื่อสารในองค์กรธุรกิจ',
              ],
            },
            {
              part: 2,
              name: 'หลักการโน้มน้าวใจ',
              chapters: [
                'หลักการโน้มน้าวใจ',
                'ช่องทางการสื่อสารโน้มน้าวใจ',
                'หลักการโน้มน้าวใจเชิงเหตุผล',
                'หลักการโน้มน้าวใจเชิงอารมณ์',
                'กลวิธีการโน้มน้าวใจ 1 KAP Model',
                'กลวิธีการโน้มน้าวใจ 2',
                'กลวิธีการโน้มน้าวใจ 3',
              ],
            },
          ],
        },
        {
          course: 2,
          name: 'Storytelling Structure and Media Design',
          des: 'เรียนรู้เกียวกับการเล่าเรื่อง การจัดทําโครงสร้างองค์ประกอบ ขั้นตอน เทคนิคการอุปมาและการเปรียบเทียบ ตลอดจนการออกแบบสื่อ',
          parts: [
            {
              part: 1,
              name: 'การสร้างสรรค์การเล่าเรื่องอย่างมีกลวิธี',
              chapters: [
                'การเล่าเรื่องและเป้าหมายของเรื่องเล่า',
                'การจัดทำโครงสร้าง',
                'องค์ประกอบของเรื่องเล่า',
                'เทคนิคการอุปมาและการเปรียบเทียบ',
                'เทคนิคการเล่าเรื่อง',
              ],
            },
            {
              part: 2,
              name: 'การออกแบบสร้างสรรค์สื่อเพื่อการสื่อสาร',
              chapters: [
                'กระบวนการออกแบบสื่อ',
                'การออกแบบสไลด์นำเสนอ',
                'การออกแบบข่าวประชาสัมพันธ์',
                'การออกแบบสื่อสังคม (Social media)',
                'การออกแบบเหตุการณ์พิเศษ (Special Event)',
                'แนวโน้มการออกแบบสื่อ',
              ],
            },
            {
              part: 3,
              name: 'บทสัมภาษณ์และกรณีศึกษาการเล่าเรื่อง',
              chapters: [
                'การเล่าเรื่องเพื่อการขาย (คุณศรุตา เกียรติภาคภูมิ Pin Metal Art)',
                'การเล่าเรื่องเพื่อสร้างแรงบันดาลใจ (ครูลูกกอลฟ์ คณาธิป สุนทรรักษ์)',
                'การเล่าเรื่องเพื่อปรับเปลี่ยนพฤติกรรม (ครูต้า อาจารย์วทิวัส สังสะกิจ)',
                'การเล่าเรื่องเพื่อการสื่อสารในภาคธุรกิจ (คุณโอบบุญ แย้มศิริกุล SCG)',
              ],
            },
          ],
        },
        {
          course: 3,
          name: 'Effective Presentation Delivery',
          des: 'เรียนรู้เกี่ยวกับหลักการนําเสนอ การจัดเตรียมโครงสร้าง องค์ประกอบ ขั้นตอน เทคนิคการอุปมาและการเปรียบเทียบ การเลือกใช้ภาษาและอวัจนภาษา รวมถึงการออกแบบและเลือกใช้สื่อที่เหมาะสม ในการนําเสนอต่าง ๆ',
          parts: [
            {
              part: 1,
              name: 'การนําเสนอ',
              chapters: [
                'หลักการนําเสนอ',
                'การวิเคราะห์ผู้ฟัง',
                'การจัดโครงสร้างการนําเสนอ',
                'ระดับของการส่งสารและวิธิการส่งสาร',
                'การประเมินผลการนําเสนอ',
              ],
            },
            {
              part: 2,
              name: 'การสร้างเนื้อหาเพื่อการนําเสนอ',
              chapters: [
                'ประเภทของเนื้อหา',
                'การใช้ภาษา',
                'การสื่อสารระหว่างวัฒนธรรม',
              ],
            },
            {
              part: 3,
              name: 'การใช้สื่อประกอบการนําเสนอ',
              chapters: [
                'หน้าที่ของสื่อประกอบการนําเสนอ',
                'ประเภทของสื่อประกอบการนําเสนอ',
                'แนวทางการใช้สื่อประกอบการนําเสนอ',
              ],
            },
            {
              part: 4,
              name: 'เทคนิคการนําเสนอ',
              chapters: [
                'การจัดการกับความประหม่า',
                'การตอบคำถาม',
                'การบริหารจัดการปฎิกริยาของผู้ฟัง',
              ],
            },
            {
              part: 5,
              name: 'การฝึกปฏิบัติการนําเสนอ',
              chapters: [
                'การนําเสนอแบบต่อหน้า',
                'การนําเสนอในที่สาธารณะ',
              ],
            },
          ],
        },
        {
          course: 4,
          name: 'Negotiation and Value Creation',
          des: 'เรียนรู้เกี่ยวกับกลยุทธ์ที่ใช้ในการเจรจาต่อรอง ประเภทของการเจรจาต่อรอง หลักการจัดการความตึงเครียดระหว่างการเจรจาต่อรอง หลักการฟังเชิงรุก (Active listening) ข้อควรระวังในเจรจาต่อรอง เเละกระบวนการเจรจาต่อรอง',
          parts: [
            {
              part: 1,
              name: 'กลยุทธ์ที่ใช้ในการเจรจาต่อรอง',
              chapters: [
                'ประเภทของการเจรจาต่อรอง',
                'หลักการจัดการความตึงเครียดระหว่างการเจรจาต่อรอง',
                'หลักการฟังเชิงรุก (Active listening)',
                'ข้อควรระวังในเจรจาต่อรอง',
              ],
            },
            {
              part: 2,
              name: 'กระบวนการเจรจาต่อรอง',
              chapters: [
                'การจัดลำดับการเจรจาต่อรอง',
                'การเตรียมการเจรจาต่อรอง',
                'จิตวิยาที่เกี่ยวข้องกับการเจรจาต่อรอง',
                'การบรรลุเป้าหมายของการเจรจาต่อรอง',
                'การทำสัญญาและข้อตกลง',
              ],
            },
            {
              part: 3,
              name: 'เทคนิคการจัดการกับข้อเรียกร้องในระหว่างการเจราจาต่อรอง',
              chapters: [
                'การสร้างเงื่อนไข I: Anchoring',
                'การสร้างเงื่อนไข II: Framing, Availability',
                'การสร้างเงื่อนไข III: Escalation, Reciprocation, Contrast',
                'การสร้างเงื่อนไข IV: การจัดการความขัดแย้งในระหว่างการเจรจาต่อรอง',
              ],
            },
          ],
        },
      ],
      instructors: [
        {
          img: require('../../assets/image/pathway2-instructor-1.png'),
          tth: 'ศ.ดร.',
          fth: 'ปาริชาต',
          lth: 'สถาปิตานนท์',
          ten: 'Prof.Dr.',
          fen: 'Parichart',
          len: 'Sathapitanont',
          dep: 'คณบดี',
          fac: 'คณะนิเทศศาสตร์',
        },
        {
          img: require('../../assets/image/pathway2-instructor-2.png'),
          tth: 'ศ.ดร.',
          fth: 'กิตติคุณ ศักดา',
          lth: 'ปั้นเหน่งเพ็ชร์',
          ten: 'Prof.Dr.',
          fen: 'Kittikun Sakda',
          len: 'Punnengpetch',
          dep: 'ภาควิชาวาทวิทยาและสื่อสารการแสดง',
          fac: 'คณะนิเทศศาสตร์',
        },
        {
          img: require('../../assets/image/pathway2-instructor-4.png'),
          tth: 'ผศ.ดร.',
          fth: 'สุกัญญา',
          lth: 'สมไพบูลย์',
          ten: 'Asst.Prof.Dr.',
          fen: 'Sukanya',
          len: 'Somphaiboon',
          dep: 'ภาควิชาวาทวิทยาและสื่อสารการแสดง',
          fac: 'คณะนิเทศศาสตร์',
        },
        {
          img: require('../../assets/image/pathway2-instructor-5.png'),
          tth: 'รศ.ดร.',
          fth: 'สมิทธิ์',
          lth: 'บุญชุติมา',
          ten: 'Assoc.Prof.Dr.',
          fen: 'Smith',
          len: 'Bunchutima',
          dep: 'ภาควิชาการประชาสัมพันธ์',
          fac: 'คณะนิเทศศาสตร์',
        },
        {
          img: require('../../assets/image/pathway2-instructor-6.png'),
          tth: 'ผศ.ดร.',
          fth: 'ธีรดา',
          lth: 'จงกลรัตนาภรณ์',
          ten: 'Asst.Prof.Dr.',
          fen: 'Theerada',
          len: 'Jongkolrattanaporn',
          dep: 'ภาควิชาการประชาสัมพันธ์',
          fac: 'คณะนิเทศศาสตร์',
        },
        {
          img: require('../../assets/image/pathway2-instructor-7.png'),
          tth: 'ผศ.ดร.',
          fth: 'ประภัสสร',
          lth: 'จันทร์สถิตย์พร',
          ten: 'Asst.Prof.Dr.',
          fen: 'Prapassorn',
          len: 'Chansathitporn',
          dep: 'ภาควิชาวาทวิทยาและสื่อสารการแสดง',
          fac: 'คณะนิเทศศาสตร์',
        },
      ],
      faqs: [
        {
          q: 'Persuasive Presentation & Negotiation Pathway คืออะไร สำคัญอย่างไร',
          a: 'ชุดวิชาที่เกี่ยวข้องกับการเสริมสร้างทักษะด้านการสื่อสารและการเจรจาให้มีประสิทธิภาพสูงสุด อีกทั้งยังช่วยในการพัฒนาบุคลิกภาพ ความมั่นใจให้กับตนเอง และเพิ่มโอกาสในความก้าวหน้าของหน้าที่การงานอีกด้วย',
        },
        {
          q: 'Persuasive Presentation & Negotiation Pathway เหมาะกับใคร',
          a: 'เหมาะสำหรับทุกอาชีพที่ต้องการเสริมสร้างทักษะทางด้านการสื่อสาร นำเสนอแบบโน้มน้าวใจ และเจรจาต่อรองเชิงธุรกิจ เช่น ผู้บริหาร, เจ้าของธุรกิจ/สตาร์ทอัพ, ผู้จัดการ, พนักงานทั่วไป และ บัณฑิตจบใหม่ เป็นต้น',
        },
        {
          q: 'เนื้อหาวิชานี้สามารถนำไปใช้ในสถานการณ์ใดได้บ้าง',
          a: 'การนำเสนอตัวเองต่อผู้อื่น การนำเสนอเพื่อโน้มน้าวใจผู้ฟัง การสื่อสารกันภายในทีมหรือสื่อสารกับบุคคลภายนอกอย่างตรงประเด็นและเป็นมืออาชีพ การเจรจาต่อรองทางธุรกิจเพื่อให้เกิดความร่วมมือระหว่างกัน',
        },
        {
          q: 'นอกจากเนื้อหาการเรียนแล้ว ผู้เรียนจะได้รับอะไรอีก',
          a: 'ผู้เรียนสามารถสอบถาม ปรึกษา และรับ Feedback จาก PA เพื่อเติมเต็มและพัฒนาแบบฝึกหัดในแต่ละคอร์สให้ดียิ่งขึ้น นอกจากนั้น เรายังมีกิจกรรมเสริมบทเรียน เช่น การออกแบบสไลด์นำเสนอ การออกแบบสื่อสังคม การออกแบบข่าวประชาสัมพันธ์ ฯลฯ กับผู้สอนในศาสตร์นั้น ๆ ที่เราจะชวนให้ผู้เรียนนำกลวิธีในคอร์สมาปฏิบัติจริง ลองนำเสนอจริงกับผู้ฟังจริง',
        },
        {
          q: 'มีแบบฝึกหัดหลังบทเรียนประเภทใดบ้างให้ผู้เรียนได้ฝึกฝน',
          a: 'แบบทดสอบย่อย, แบบทดสอบก่อนเรียน, แบบทดสอบหลังเรียน, การส่งไฟล์ประกอบกิจกรรมเสริมบทเรียน, การส่งไฟล์สไลด์นำเสนอ เป็นต้น',
        },
        {
          q: 'การเรียน Pathway ต่างจากการเรียนคอร์สทั่วไปอย่างไร',
          a: 'เป็นการเรียนรู้จากทฤษฎีพื้นฐานไปสู่การนำไปประยุกต์ใช้ โดยเริ่มต้นจากการรู้จักและเรียนรู้เครื่องมือในการเขียนโปรแกรมขั้นพื้นฐานที่สามารถนำไปใช้ในการจัดการกับข้อมูลจำนวนไม่มากและไม่ซับซ้อน ไปสู่การเรียนรู้เครื่องมือที่สามารถจัดการกับข้อมูลจำนวนมากและมีความซับซ้อนขึ้นได้ จนในที่สุดเมื่อผู้เรียนได้รู้จักเครื่องมือในการเขียนโปรแกรมใน Pathway นี้แล้วจะสามารถนำทักษะดังกล่าวไปประยุกต์ใช้กับข้อมูลในชีวิตประจำวันได้',
        },
        {
          q: 'Certification พิเศษอย่างไร',
          a: 'เป็น Institute Certificate ที่ได้รับการรับรองจากจุฬาลงกรณ์มหาวิทยาลัยซึ่งเป็นสถาบันการศึกษาชั้นนำของประเทศ',
        },
        {
          q: 'หากมีคำถามหรือข้อสงสัยระหว่างเรียน สามารถติดต่อได้ทางช่องทางไหนได้บ้าง',
          a: 'ผ่าน Facebook Closed Group สำหรับผู้เรียนโดยเฉพาะโดยมี PA (Pathway Assistant) เป็นผู้ดูแลให้ความช่วยเหลือ รวมถึงจัดกิจกรรมเสริมสร้างการเรียนรู้ ไม่ว่าจะเป็นการตอบข้อสงสัยเกี่ยวกับบทเรียน การให้เอกสารหรือกรณีศึกษาเพิ่มเติมประกอบวิชา เป็นต้น',
        },
        {
          q: 'ต้องมีความรู้พื้นฐานอะไรมาก่อนบ้างจึงจะสามารถจะเรียนได้',
          a: 'ผู้เรียนไม่จำเป็นต้องมีความรู้พื้นฐานใด ๆ แค่มีความต้องการที่จะเรียนรู้ และพัฒนาทักษะทางการสื่อสารให้มีประสิทธิภาพ หมั่นค้นคว้าหาความรู้หรือไอเดียใหม่ ๆ อยู่เสมอ',
        },
        {
          q: 'ควรรู้จักเครื่องมืออะไรบ้างที่ใช้ในการนําเสนอ',
          a: 'Microsoft PowerPoint, Google Slides และโปรแกรมอื่น ๆ ที่ใช้ในการนำเสนอได้',
        },
      ],
    };
  },
};
</script>
<style scoped>
.ct-mw{
  max-width: 1100px;
  margin: auto;
}
.banner-h {
   height: 200px;
}
.banner-bg {
  background-image: linear-gradient(90deg, #003a70, hsla(0, 100%, 100%, 0) 10%, #003a70 96%),
    url('../../assets/image/presentation-negotiation.jpeg');
  background-position: center;
  background-size: cover;
  height: 250px;

  /* margin-left: -600px;
  margin-right: -600px; */
}
.line-banner {
  border: none;
  border-left: 0.4rem solid #fff;
  height: 6rem;
  width: 0.5rem;
  max-inline-size: min-content;
}
.banner-content{
  max-width: 1100px;
  margin: auto;
}
.content{
  height: 1500px;
  margin: auto;
  max-width: 1200px;
}
.heading{
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.pd-24px{
  margin-left: 31px;
  margin-right: 32px;
}
.pd-12px{
  padding-left: 12px;
}
.pdr-16px{
  padding-left: 24px;
  padding-right: 16px;
}
.white-bg{
  box-shadow: #001544;
  border: #001544;
}
.shadow{
  box-shadow: 2px 2px 10px #dfdfdf;
  border-color: #001544;
}
.mt-24px{
  margin-top: 24px;
}
.mlr-24px{
  margin-left: 24px;
  margin-right: 24px;
}
.v-icon{
  font-size: 16px;
}
.mgt-12px{
  margin-top: 12px;
}
.v-btn__content{
  font-weight: 700;
}
.ltsp{
  font-weight: 700;
  letter-spacing: 2px;
}
.mg{
  margin-bottom: 16px;
}
.prof {
  line-height: 20px;
  font-size: 0.75rem;
}
@media screen and (max-width: 770px){
  .heading{
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4rem;
  }
}
</style>
